import * as types from '../types'

const coins = (state = [], action) => {
  switch (action.type) {
    case types.INITIAL_COIN_SET_SUCCESS:
      return action.coins

    default:
      return state
  }
}

export default coins
