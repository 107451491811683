import { combineReducers } from 'redux'

import redCoins from './coins'
import redOriginCoin from './originCoin'
import redPrices from './prices'
import redSearch from './search'
import redTargetCoin from './targetCoin'
import redTransaction from './transaction'

export default combineReducers({
  coins: redCoins,
  originCoin: redOriginCoin,
  prices: redPrices,
  search: redSearch,
  targetCoin: redTargetCoin,
  transaction: redTransaction,
})
