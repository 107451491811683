import * as types from '../types'

const initialState = {
  loading: false,
  email: '',
  wallet: '',
  completedTransaction: {},
  step: 1,
  errorMessage: '',
}

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRANSACTION_EMAIL:
      return Object.assign({}, state, { email: action.email })

    case types.SET_TRANSACTION_WALLET:
      return Object.assign({}, state, { wallet: action.wallet })

    case types.POST_TRANSACTION_REQUEST:
      return Object.assign({}, state, { loading: true })

    case types.POST_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        completedTransaction: action.tx,
        step: 2,
      })

    case types.POST_TRANSACTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: action.message,
      })

    case types.FINISH_TRANSACTION:
      return Object.assign({}, initialState)

    default:
      return state
  }
}

export default transaction
