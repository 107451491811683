// Actions for the initial load of coin information
export const INITIAL_COIN_SET_REQUEST = 'INITIAL_COIN_SET_REQUEST'
export const INITIAL_COIN_SET_SUCCESS = 'INITIAL_COIN_SET_SUCCESS'
export const INITIAL_COIN_SET_FAILURE = 'INITIAL_COIN_SET_FAILURE'

// Actions when user selects an origin coin
export const SET_ORIGIN_COIN_REQUEST = 'SET_ORIGIN_COIN_REQUEST'
export const SET_ORIGIN_COIN_FAILURE = 'SET_ORIGIN_COIN_FAILURE'

// Actions when user selects an origin coin
export const SET_TARGET_COIN_REQUEST = 'SET_TARGET_COIN_REQUEST'
export const SET_TARGET_COIN_FAILURE = 'SET_TARGET_COIN_FAILURE'

// Update transaction values based on the information provided
export const SET_ORIGIN_COIN_VALUE = 'SET_ORIGIN_COIN_VALUE'
export const SET_TARGET_COIN_VALUE = 'SET_TARGET_COIN_VALUE'
export const UPDATE_TRANSACTION_VALUES = 'UPDATE_TRANSACTION_VALUES'

// Switch the values of origin and target coins
export const SWITCH_COINS = 'SWITCH_COINS'

// Set user contact information
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_USER_WALLET = 'SET_USER_WALLET'

// Search transaction action tyes
export const SET_SEARCH_REFERENCE = 'SET_SEARCH_REFERENCE'
export const SEARCH_TRANSACTION_REQUEST = 'SEARCH_TRANSACTION_REQUEST'
export const SEARCH_TRANSACTION_SUCCESS = 'SEARCH_TRANSACTION_SUCCESS'
export const SEARCH_TRANSACTION_FAILURE = 'SEARCH_TRANSACTION_FAILURE'

// Transaction action types
export const SET_TRANSACTION_EMAIL = 'SET_TRANSACTION_EMAIL'
export const SET_TRANSACTION_WALLET = 'SET_TRANSACTION_WALLET'
export const POST_TRANSACTION_REQUEST = 'POST_TRANSACTION_REQUEST'
export const POST_TRANSACTION_SUCCESS = 'POST_TRANSACTION_SUCCESS'
export const POST_TRANSACTION_FAILURE = 'POST_TRANSACTION_FAILURE'
export const FINISH_TRANSACTION = 'FINISH_TRANSACTION'
