import * as types from '../types'

const initialState = {
  reference: '',
  loading: false,
  result: null,
  message: '',
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_TRANSACTION_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        reference: action.reference,
      })

    case types.SEARCH_TRANSACTION_SUCCESS:
      return Object.assign({}, state, { loading: false, result: action.tx })

    case types.SEARCH_TRANSACTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        result: null,
        message: action.message,
      })

    case types.SET_SEARCH_REFERENCE:
      return Object.assign({}, state, { reference: action.reference })

    default:
      return state
  }
}

export default search
