import * as types from '../types'

const initialState = {
  available: [],
  selected: undefined,
  value: 1,
  loading: false,
  useStable: false,
}

/**
 * Helper to round values to the provided number of decimal places.
 *
 * @param {Float} value The value to round.
 * @param {Integer} decimals The number of decimal places.
 */
function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function calcValue({ prices, originCoin, targetCoin, targetValue }) {
  const tradePrice = parseFloat(prices[`${originCoin.key}${targetCoin.key}`])
  return round(targetValue / tradePrice, 6)
}

const originCoin = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_COINS:
      return Object.assign({}, state, {
        selected: action.targetSelected,
        available: action.coins.filter(c => !state.useStable ? c.isStable : !c.isStable),
        value: action.targetValue,
        useStable: !state.useStable,
      })

    case types.INITIAL_COIN_SET_REQUEST:
      return Object.assign({}, state, { loading: true })

    case types.INITIAL_COIN_SET_SUCCESS:
      return Object.assign({}, state, {
        selected: action.originSelected,
        available: action.coins.filter(c => state.useStable ? c.isStable : !c.isStable),
        loading: false,
      })

    case types.INITIAL_COIN_SET_FAILURE:
      return Object.assign({}, state, { loading: false })

    case types.SET_ORIGIN_COIN_REQUEST:
      return Object.assign({}, state, { selected: action.coin, loading: true })

    case types.SET_ORIGIN_COIN_VALUE:
      return Object.assign({}, state, { value: action.value })

    case types.UPDATE_TRANSACTION_VALUES:
      return action.targetValue !== undefined
        ? Object.assign({}, state, {
            selected: action.originCoin,
            available: action.coins.filter(c => state.useStable ? c.isStable : !c.isStable),
            value: calcValue(action),
            loading: false,
          })
        : Object.assign({}, state, { loading: false })

    default:
      return state
  }
}

export default originCoin
