import * as types from '../types'

const initialState = {
  available: [],
  selected: undefined,
  value: 0.0,
  loading: false,
  useStable: true,
}

/**
 * Helper to round values to the provided number of decimal places.
 *
 * @param {Float} value The value to round.
 * @param {Integer} decimals The number of decimal places.
 */
function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

function calcValue({ prices, originCoin, originValue, targetCoin }) {
  const tradePrices = prices.filter(
    price =>
      price.depositCoin === originCoin.key.toLowerCase() &&
      price.destinationCoin === targetCoin.key.toLowerCase()
  )

  return tradePrices.length > 0
    ? round(originValue * tradePrices[0].rate, 6)
    : 'Low liquidity'
}

const targetCoin = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_COINS:
      return Object.assign({}, state, {
        selected: action.originSelected,
        available: action.coins.filter(c =>
          !state.useStable ? c.isStable : !c.isStable
        ),
        value: calcValue({
          prices: action.prices,
          originCoin: action.targetSelected,
          originValue: action.targetValue,
          targetCoin: action.originSelected,
        }),
        useStable: !state.useStable,
      })

    case types.INITIAL_COIN_SET_REQUEST:
      return Object.assign({}, state, { loading: true })

    case types.INITIAL_COIN_SET_SUCCESS:
      return Object.assign({}, state, {
        selected: action.targetSelected,
        available: action.coins.filter(c =>
          state.useStable ? c.isStable : !c.isStable
        ),
        loading: false,
      })

    case types.UPDATE_TRANSACTION_VALUES:
      return action.originValue !== undefined
        ? Object.assign({}, state, {
            selected: action.targetCoin,
            available: action.coins.filter(c =>
              state.useStable ? c.isStable : !c.isStable
            ),
            value: calcValue(action),
            loading: false,
          })
        : Object.assign({}, state, { loading: false })

    case types.INITIAL_COIN_SET_FAILURE:
    case types.SET_TARGET_COIN_FAILURE:
      return Object.assign({}, state, { loading: false })

    case types.SET_TARGET_COIN_REQUEST:
      return Object.assign({}, state, { selected: action.coin, loading: true })

    case types.SET_TARGET_COIN_VALUE:
      return Object.assign({}, state, { value: action.value })

    default:
      return state
  }
}

export default targetCoin
