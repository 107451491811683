import * as types from '../types'

const prices = (state = {}, action) => {
  switch (action.type) {
    case types.INITIAL_COIN_SET_SUCCESS:
      return action.prices

    default:
      return state
  }
}

export default prices
